/* src/App.js */
import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
//import { listChoices } from './graphql/queries'
import { createChoice } from './graphql/mutations'
import { votesByDate } from './graphql/queries'
import awsExports from './aws-exports'

Amplify.configure(awsExports);

const initialState = { name: '', description: '', animal: 'cat', type: 'vote' }

const App = () => {

  const [formState, setFormState] = useState(initialState)
  const [choices, setchoices] = useState([])

  useEffect(() => {
    fetchchoices()
  }, [])

  async function fetchchoices() {
    try {
      const choiceData = await API.graphql(graphqlOperation(votesByDate, {
        limit: 10,
        sortDirection: "DESC",
        type: "vote"
      },
      ))
      const choices = choiceData.data.votesByDate.items
      setchoices(choices)
    } catch (err) { console.log(err) }
  }

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function addchoice() {
    try {
      if (!formState.name || !formState.description || !formState.animal) return
      const choice = { ...formState }
      await API.graphql(graphqlOperation(createChoice, { input: choice }))
      fetchchoices()
      setFormState(initialState)
    } catch (err) {
      console.log('error creating choice:', err)
    }
  }

  function animal_link_generator(animal, date) {
    // months https://stackoverflow.com/questions/1643320/get-month-name-from-date
    const monthNames = [
      "January", 
      "February", 
      "March", 
      "April", 
      "May", 
      "June",
      "July", 
      "August", 
      "September", 
      "October", 
      "November", 
      "December"
    ];
    const day = date.substring(8,10)
    const month = monthNames[parseInt(date.substring(5,7), 10) - 1]
    const year = date.substring(0,4)
    // url format 
    // https://catoftheday.com/archive/2022/March/17.html
    return 'http://'
      .concat(animal)
      .concat('oftheday.com/archive/')
      .concat(year)
      .concat('/')
      .concat(month)
      .concat('/')
      .concat(day)
      .concat('.html')
  }

  return (
    <div style={styles.container}>
      <h1>Liste des 10 derniers choix</h1>
      <table style={styles.table_td_th}>
        <thead>
          <tr style={styles.table_td_th, styles.th}>
            <th style={styles.table_td_th}>Nom</th>
            <th style={styles.table_td_th}>Animal</th>
            <th style={styles.table_td_th}>Description</th>
            <th style={styles.table_td_th}>Date</th>
            <th style={styles.table_td_th}>Link</th>
          </tr>
        </thead>
        {
          choices.map((choice) => 
            (
            <tbody style={styles.table_td_th} >
              {
                <tr key={choice.id} style={styles.table_td_th} >
                  <td style={styles.table_td_th}>{choice.name}</td>
                  <td style={styles.table_td_th}>{choice.animal}</td>
                  <td style={styles.table_td_th}>{choice.description}</td>
                  <td style={styles.table_td_th}>{choice.createdAt.substring(0,10)}</td>
                  <td style={styles.table_td_th}><a href={animal_link_generator(choice.animal, choice.createdAt)}>Link {choice.animal}</a></td>
                </tr>
              }
            </tbody>
          ))
        }
      </table>
      <h2>Sélectionnez votre animal préféré</h2>
      <input
        onChange={event => setInput('name', event.target.value)}
        style={styles.input}
        value={formState.name}
        maxLength="50"
        placeholder="Name"
        required
      />
      <input
        onChange={event => setInput('description', event.target.value)}
        style={styles.input}
        maxLength="150"
        value={formState.description}
        placeholder="Description"
      />
      <select
        onChange={event => setInput('animal', event.target.value)}
        style={styles.input}
        value={formState.animal}
        placeholder="Animal"
      >
        <option value="cat" defaultValue>Cat</option>
        <option value="dog">Dog</option>
        <option value="pet">Pet</option>
      </select>

      <button style={styles.button} onClick={addchoice}>Create choice</button>
    </div>
  )
}

const styles = {
  container: { width: 400, margin: '0 auto', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', padding: 20 },
  choice: { marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  choiceName: { fontSize: 20, fontWeight: 'bold' },
  choiceDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' },
  table_td_th: { width: '40%', border: '1px solid green', textalign: 'center' },
  th: { backgroundcolor: 'green', color: 'black' },
  image_cat: { float: 'left', width: "100", height: "50" },
  image_dog: { float: 'rigth', width: "100", height: "50" }
}


export default App;
